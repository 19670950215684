footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent background for contrast */
    font-size: 16px; /* Adjust based on your design */
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 0rem 0;
  }
  
footer a {
    color: var(--sand-white); /* Ensures links are white */
    text-decoration: none; /* Removes underline from links */
    transition: color 0.3s; /* Smooth transition for hover effect */
  }
  
  footer a:hover {
    background-color: var(--sand-white); /* Change background color on hover */
    color: var(--white-gold); /* Change text color on hover */
}

footer p {
  padding: 0 2rem;
}
  
  @media (max-width: 768px) {
    footer {
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: 0rem 0;
    }
  
    footer p {
      margin: 10px 0; /* Adjust vertical spacing for mobile */
    }
  }