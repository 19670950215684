@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');
.myllaPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    background-color: #0A0A0A; /* Dark background for that arcade feel */
    color: white;
    font-family: 'Press Start 2P', cursive, sans-serif;
    padding-bottom: 7rem;
  }

  /* Styles to apply specifically on the MyllaPage */
.mylla-header {
  background-image: none;
  background-color: #0A0A0A;
  height: 5rem;
}

.mylla-header .bmb {
  display: none;
}

  .myllaPage button {
    font-family: 'Press Start 2P', cursive, sans-serif;
    font-size: 1rem;
    padding: 10px 20px;
    margin-top: 1.5rem;
    background-color: #009600; /* Bright green button background */
    color: black; /* Black text for better readability */
    border: none;
    border-radius: 5px; /* Rounded corners for a softer look */
    cursor: pointer;
    transition: background-color 0.5s; /* Smooth transitions for interactions */
  }

  .myllaPage .welcome h2, .myllaPage .emails {    
    font-family: "Play", sans-serif, cursive;
  }

  .myllaPage button:hover {
    background-color: #00FF00; /* Lighten button background on hover */
    border: none;
    
  }

  .myllaPage input {
    font-family: 'Press Start 2P', cursive, sans-serif;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-top: 1.5rem;
    background-color: #ffffff; /* Bright green button background */
    color: black; /* Black text for better readability */
    border: none;
    border-radius: 5px; /* Rounded corners for a softer look */
    transition: background-color 0.5s; /* Smooth transitions for interactions */
  }

  .myllaPage form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .board {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: repeat(3, 100px);
    gap: 10px;
    justify-content: center;
    background-color: #212121; /* Darker board background */
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 255, 0, 0.8); /* Neon-like glow */
    margin-bottom: 1rem;
  }
  
  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212; /* Even darker cell background */
    cursor: pointer;
    font-size: 2rem;
    border: 4px solid #00FF00; /* Bright green border for contrast */
    color: white; /* White text for better readability */
    font-weight: bold;
    border-radius: 5px; /* Rounded corners for a softer look */
    transition: background-color 0.5s, transform 0.2s; /* Smooth transitions for interactions */
  }
  
  .cell:hover {
    background-color: #1E1E1E; /* Lighten cell background on hover */
    transform: scale(1.1); /* Slightly enlarge cells on hover */
  }
  
  .status {
    color: #00FF00; /* Bright green text for game status */
    text-align: center;
    margin-bottom: 20px;
  }

  @keyframes flash {
    0%, 100% { background-color: #121212; }
    50% { background-color: #00FF00; }
  }
  
  .winner {
    animation: flash 0.5s infinite;
  }
  
  @media (max-width: 600px) {
    .board {
      grid-template-columns: repeat(3, 70px); /* Smaller cells for mobile */
      grid-template-rows: repeat(3, 70px); /* Smaller cells for mobile */
      gap: 5px;
    }


  
    .cell {
      font-size: 1.5rem; /* Smaller font size for mobile */
    }
  }