.bio-and-photo-container {
  display: flex;
  gap: 20px; /* Adds space between the logo and the bio text */
  padding: 3% 20px;
  background-color: var(--sand-white);
  color: black;
}

.my-bio-photo {
  flex-basis: 28%;
  height: 100px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.my-bio-photo img {
  width: 250px; /* Or any size you prefer */
  height: auto;
  border-radius: 40%; /* Rounds the corners of the image */
}

/* Styling for the bio text container */
.my-bio-container {
  text-align: left;
  padding: 20px;
  max-width: 700px;
}

.my-bio-container h1 {
display: flex;}

.my-bio-container h4 {
  font-size: 1.25rem;
  margin-bottom: 0.2rem;
  padding-bottom: 0.25rem;
  display: inline-block;
}

@media (max-width: 768px) {
  .bio-and-photo-container {
      flex-direction: column;
    }

    .my-bio-photo {
      order: 1;
    }
    .my-bio-photo {
      margin-top: 0rem;
    }
    .my-bio-photo img {
      width: 200px; /* Or any size you prefer */
      height: auto; /* Keeps the aspect ratio of the image */
    }
    .my-bio-container h1 {
      width: 85%;    
    }
  }