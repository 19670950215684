
.App-header {
    height: 200px; /* Initial height */
    display: flex;
    padding: 0.5rem 0.1rem; /* Adjusted padding */
    align-items: center;
  }

  .header-content {
    width: 100%; /* Ensures the header content takes the full width */
    display: flex;
    flex-direction: column;
    align-items: center;
}

  .bmb {
    display: flex;
    justify-content: space-between; /* Align items to each side */
    align-items: center;
    width: 250px;
  }
  
  .mobile-nav {
    top: 0;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(-45deg, var(--dark-gray), var(--dark-purple), black, var(--white-gold));
    background-size: 200% 200%; /* Set background size to allow movement */
    animation: sandSmokeEffect 20s ease infinite;
    transition: all 0.3s ease;

}

@keyframes sandSmokeEffect {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

  .mobile-nav.active {
    display: block; /* Show when active */
  }
  
  .mobile-nav ul {
    list-style-type: none;
    margin: 0;
    padding:3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%; /* Takes full height of its parent */
    width: 100%; /* Full width for better touch targets */
    justify-content: space-around; /* Evenly distributes children vertically */
}
.mobile-nav .line { 
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    width: 70%;
}

.mobile-nav ul li {
  width: 100%;
  text-align: center;
}

.mobile-nav a:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255,255,255,0.5);
}
  
  .mobile-nav a {
    text-decoration: none;
    color: var(--sand-white); /* Example color */
    display: block;
    font-size: 2rem;
    padding: 1.5rem 0;}

  .mobile-nav a:hover, .mobile-nav a.active{
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--sand-white); /* Example color */
  }

  .hamburger {
    /* mix-blend-mode: difference;  or 'exclusion' */
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px; /* Adjust the width as needed */
    height: 48px; /* Adjust the height as needed */
    position: fixed; /* Positions the icon lines within */
    top: 0; /* Top of the viewport */
    right: 0; /* Left of the viewport */
    z-index: 100; /* Ensure it's above other content */
    padding: 1rem;
  }
  
  .hamburger .icon,
  .hamburger .icon::before,
  .hamburger .icon::after {
    content: '';
    background-color: var(--sand-white); /* or any color that stands out against your header */
    height: 2px;
    width: 30px; /* Adjust based on your design preference */
    position: absolute;
    transition: transform 0.3s ease;
  }
  
  .hamburger .icon::before { transform: translateY(-10px) translateX(-50%); }
  .hamburger .icon::after { transform: translateY(10px) translateX(-50%); }
  
  .hamburger .icon.open::before { transform: rotate(45deg) translateX(-50%) translate(5px, 10px); }
  .hamburger .icon.open::after { transform: rotate(-45deg) translateX(-50%) translate(5px, -10px); }
  .hamburger .icon.open { background-color: transparent; }

  .b {
    border-left: 2px solid var(--sand-white); /* Assuming $font-color is black */
    height: 125px;
    position: relative; /* Ensures pseudo-elements position relative to this */
    width: 60px;
  }
  
  .b:before,
  .b:after {
    content: '';
    position: absolute;
    border-color: var(--sand-white); /* Assuming $font-color is black */
    border-width: 2px 2px 2px 0;
    border-style: solid;
  }
  
  .b:before {
    border-radius: 0 240% 180% 0 / 0 180% 180% 0;
    height: 52px;
    top: 0;
    width: 52px;
    left: 2px; /* Adjust 'left' to close the gap */
  }
  
  .b:after {
    border-radius: 0 180% 220% 0 / 0 180% 180% 0;
    height: 67px;
    bottom: 0;
    width: 66px;
    left: -2px; /* Adjust 'left' to close the gap */
  }

  .m {
    margin-top: -40px;
    border-left: 2px solid var(--sand-white);
    border-right: 2px solid var(--sand-white);
    height: 125px;
    overflow: hidden;
    top: 20px;
    width: 80px;
    position: relative; /* Ensure position is set for absolute positioning of children */
  }
  
  .m:before {
    background: var(--sand-white);
    content: '';
    height: 125px;
    position: absolute;
    top: 0;
    right: 19px;
    transform: skew(-18deg);
    width: 2px;
  }
  
  .m:after {
    background: var(--sand-white);
    content: '';
    height: 125px;
    position: absolute;
    top: 0;
    left: 19px;
    transform: skew(18deg);
    width: 2px;
  }



/* Apply neon glow to the lines specifically */


.neon-effect .b, 
.neon-effect .b:before, 
.neon-effect .b:after,
.neon-effect .m,
.neon-effect .m:before, 
.neon-effect .m:after {
  filter: url(#neon-glow);
  animation: neon-glow 5s infinite alternate;
}

/* General transitions */
.b, .m {
    transition: transform 1s ease-in-out;
}

/* Animation for B's switching positions */
.switch-positions .b:first-child {
    transform: translateX(300%); /* Adjust this value based on the actual width of your elements */
}

.switch-positions .b:last-child {
    transform: translateX(-300%);
}

/* Flip animation specific to M */
.flip-effect {
    animation: flip 1s ease-in-out forwards;
}

@keyframes flip {
    0%, 100% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
}


@keyframes neon-glow {
  0% {
    box-shadow: 0px -300px 100px var(--dark-purple);
  }
}

@media (max-width: 768px) {
  .hamburger {
    padding: 0.3rem;
    width: 36px;
    height: 36px;
  }
}