@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(-45deg, var(--dark-gray), var(--dark-purple), black, var(--white-gold));
  background-size: 400% 400%;
  animation: sandSmokeEffect 20s ease infinite;
  text-align: center;
  color: var(--sand-white);
}

body.no-animation {
  animation: none !important; /* Stop all animations */
}

:root {
  --dark-purple: rgb(89, 89, 199);
  --white-gold: #ac9d3d;
  --dark-gray: #333333;
  --sand-white: #f4ecda;
}

iframe {
  border: none;
  width: 90vw;
  height: 55vw;
  max-width: 700px;
  max-height: 400px;
}

@keyframes sandSmokeEffect {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

button {
  background-color: #000000;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

html, body, #root {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

/* This ensures content between header and footer stretches to fill available space */
.Routes {
  flex: 1;
  display: flex;
  flex-direction: column;
}