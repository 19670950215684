.contact-section {
    color: black;
    padding: 20px 0;
    margin: 50px  7.5%;
    border-radius: 0;
    background-color: var(--sand-white);
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 400px;
    align-self: start;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }

  .contact-section h2, .contact-section h4 {
    padding: 0 1rem;
    margin: 0px;
    font-weight: 600;
  }

  .contact-section h4 {
    padding: 1rem 1rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    background-color: var(--sand-white);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .contact-form textarea {
    height: 5rem;
  }
  
  .contact-form button {
    color: rgb(0, 0, 0);
    background-color: var(--sand-white);
    padding: 10px 20px;
    border: 2px solid #000000;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .contact-form button:hover {
    background-color: var(--dark-gray);
    color: var(--sand-white);
  }

  @media (max-width: 768px) {
    .contact-section {
      width: 100%;
      margin: 50px 0;}
  }