.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .welcome-section {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    width: 85%;
    border-bottom: 1px solid rgba(255,255,255,0.3);
  }

  .welcome-section h2, .welcome-section p {
    padding-left: 5rem;
    font-weight: bold;
  }

  .homepage-container .divider {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    width: 85%;
  }

  .divider .about-teaser {
    align-items: end;
    text-align: start;
    padding: 0 5rem;
  }

  .welcome-section h2, .welcome-section p {
    padding-right: 2rem;
    font-weight: 200;
    text-align: left;
    font-size: 1rem;
  }

  .welcome-section p {
    font-size: 1.5rem;
  }

  .homepage-container .music-page {
    display: flex;
    width: 85%;
    padding:  3rem 0;
    border-bottom: 1px solid rgba(255,255,255,0.3);
  }
  
  .welcome-section, .featured-project, .about-teaser, .latest-news, .contact-cta {
    margin-bottom: 40px;
  }

.song-title {
    text-align: center;
}

a {
    color: var(--sand-white);
    font-weight: 200;
}

@media (max-width: 768px) {
    .homepage-container .divider {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .welcome-section h2, .welcome-section p {
      padding: 0;
      text-align: center;
    }

    iframe {
      width: 100%;
    }
  
    .welcome-section p {
      font-size: 1.5rem;
    }

    .divider .about-teaser{
      align-items: center;
      text-align: center;
      padding: 0;
    }

    .homepage-container .music-page {
      width: 100%;
    }
  }